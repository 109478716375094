
export const environment = {
    production: false,
    authApiUrl: 'https://dev.fitnesslabs.me/user-management/',
    apiURL: 'https://dev.fitnesslabs.me/user-management/',
    baseUrl: 'https://dev.fitnesslabs.me/',
    filesApiUrl: 'https://dev.fitnesslabs.me/gcs/gcs/',
    recaptchaKey: '6Lf8wgEgAAAAAHly0j9e51Uzi6dZ5PvyqDw9O9PB',
    stripePublishableKey: 'pk_test_51QepIGG4bdBRwELsZZ9tJzpGY2Zwu5iRDUTjaBSD4N8q3T91jTUtFyU9NkbD7VXNlH4epj0IGrqHHW8sLNh33S3D002eIZB36l'
};
